import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

import cloneDeep from 'lodash.clonedeep';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import NameAndRecipients from './NameAndRecipients';
import FilterContacts from './FilterContacts';
import Schedule from './Schedule';
import SelectTemplate from './SelectTemplate';
// import SelectLanguage from './SelectLanguage';
import Messages from './Messages';
import Survey from './Survey';
import ReviewCampaign from './ReviewCampaign';


import {getCampaignTags, getLanguages, getTemplates} from '../../../redux/actions/configs';
import {getGroups} from '../../../redux/actions/groups';
import {
    createCampaign,
    clearCampaignForm,
    decrementCampaignStep, incrementCampaignStep, setCampaignStep
} from '../../../redux/actions/campaigns';
import {formatTemplateVariables} from '../../../utils/template';

import {GROUP_FILTER_TYPE} from '../../constants';
import SelectLanguage from "./SelectLanguage";

const BASE_STEPS = [
    {
        label: 'Name and Recipients',
        component: NameAndRecipients
    },

    {
        label: 'Schedule',
        component: Schedule
    },
    {
        label: 'Template',
        component: SelectTemplate
    },
    {
        label: 'Language',
        component: SelectLanguage
    },
    {
        label: 'Messages',
        component: Messages
    },
    {
        label: 'Survey',
        component: Survey
    },
    {
        label: 'Review',
        component: ReviewCampaign
    }
];

let STEPS = cloneDeep(BASE_STEPS);

const CreateCampaign = () => {
    const dispatch = useDispatch();
    const [isCurrentStepValid, setIsCurrentStepValid] = useState(false);
    const {create_campaign_pending, create_campaign_success, campaign_form} = useSelector(state => state.campaigns);
    const {filter_groups} = useSelector(state => state.campaigns.campaign_form);
    const create_campaign_active_step = useSelector(state => state.campaigns.create_campaign_active_step);

    useEffect(() => {
        dispatch(getLanguages());
        dispatch(getCampaignTags());
        dispatch(getTemplates());
        dispatch(getGroups())
    }, []);

    useEffect(() => {
        STEPS = cloneDeep(BASE_STEPS);
        filter_groups.forEach((group) => {
            STEPS.splice(1, 0, {
                label: `Filter Contacts (${group.group_name})`,
                component: FilterContacts.bind(this, group),
                disabled: true
            })
        })

    }, [filter_groups])

    useEffect(() => {
        if (create_campaign_success) {
            dispatch(setCampaignStep(0))
            dispatch(clearCampaignForm());
        }
    }, [create_campaign_success])

    const handleBack = () => {
        dispatch(decrementCampaignStep());
    }

    const handleNext = () => {
        if (create_campaign_active_step === STEPS.length - 1) {
            submitCreateCampaignRequest();
        } else {
            dispatch(incrementCampaignStep());
        }
    }

    const submitCreateCampaignRequest = () => {
        const templateText = campaign_form.template.variables ? formatTemplateVariables(campaign_form.template.template, campaign_form.template.variables_value_mappings) : campaign_form.template.template;
        const campain_members_flattened = Object.values(campaign_form.campaign_members).reduce((acc, group_members) => acc.concat(group_members), [])

        const create_campaign_payload = {
            ...campaign_form,
            campaign_form: campaign_form,
            template_message: templateText,
            reprompt_message: campaign_form.template.reprompt_message,
            opt_in_keyword: campaign_form.template.opt_in_keyword,
            groups: campaign_form.groups.filter(group => group.type !== GROUP_FILTER_TYPE).map(group => group.group_id),
            campaign_members: campain_members_flattened,
            survey: {...campaign_form.survey, languages: campaign_form.languages},
            template_variables: campaign_form.template?.variables_value_mappings || {},
			content_template_id: campaign_form.template?.content_template_id
        };

        dispatch(createCampaign(create_campaign_payload));
    }

    const handleIsStepValidChange = (isValid) => {
        setIsCurrentStepValid(isValid);
    }

    const renderActiveStepComponent = () => {
        return React.createElement(STEPS[create_campaign_active_step].component, {isValid: handleIsStepValidChange});
    }

    return (
        <Container>
            <Stepper activeStep={create_campaign_active_step} alternativeLabel>
                {
                    STEPS.map((step, index) => {

                        return (
                            <Step key={step.label}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        )

                    })
                }
            </Stepper>

            <StepContainer>
                {renderActiveStepComponent()}
            </StepContainer>

            <div style={{alignSelf: 'center'}}>
                <Button disabled={create_campaign_active_step === 0 || create_campaign_pending}
                        onClick={handleBack}>Back</Button>
                <Button variant="contained" color="primary" onClick={handleNext}
                        disabled={!isCurrentStepValid || create_campaign_pending}>
                    {create_campaign_active_step === STEPS.length - 1 ? 'Confirm' : 'Next'}
                </Button>
            </div>


        </Container>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const StepContainer = styled.div`
  padding: 32px;
`;

export default CreateCampaign;