import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styled from 'styled-components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import CreateSurvey from './components/CreateSurvey';

import {setCampaignFormField} from '../../../redux/actions/campaigns';

const Survey = ({isValid}) => {
	const dispatch = useDispatch();
	const {has_survey} = useSelector(state => state.campaigns.campaign_form);
	const {form_errors} = useSelector(state => state.campaigns);

	useEffect(() => {
		if(has_survey) {
			isValid(isSurveyValid());
		}
		else{
			isValid(true);
		}
	}, [form_errors, has_survey]);
	
	const isSurveyValid = () => {
		let valid = true;
		
		// Deals with the case where the survey has been cloned
		// and the survey has not been filled out
		if (typeof form_errors['survey'] === 'string') {
			return !has_survey;
		}

		if(form_errors['survey']) {
			const hasError = form_errors['survey'].some(errorObject => {
				if(errorObject.rating) {
					return true;
				}

				const hasTextError = Object.keys(errorObject['text']).some(text => {
					if(errorObject['text'][text]) {
						return true;
					}
				});

				if(hasTextError) {
					return true;
				}

				if(errorObject.options) {
					const hasOptionsError = errorObject['options'].some(optionErrorObject => {
						const hasOptionError = Object.keys(optionErrorObject).some(language => {
							return !(optionErrorObject[language] === false);
						});

						return hasOptionError;
					});

					if(hasOptionsError) {
						return true;
					}
				}
			});

			valid = !hasError;
		}

		return valid;
	}

	const handleHasSurveyChange = (event, checked) => {
		dispatch(setCampaignFormField('has_survey', checked));
	}

	const renderCreateSurvey = () => {
		if(has_survey) {
			return <CreateSurvey />
		}
	}

	return (
		<Container>
			<FormControlLabel
				style={{alignSelf: 'center'}}
				control={<Checkbox checked={has_survey} onChange={handleHasSurveyChange} />}
				label="Include survey"
			/>

			{renderCreateSurvey()}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export default Survey;