import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CSVReader from "react-csv-reader";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ContactsIcon from '@material-ui/icons/Contacts';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';

import { normalizeTelephoneNumber } from '../../utils/phone-number';

import { setGroupFormField, createGroup, clearGroupForm } from '../../redux/actions/groups';
import { notistackError } from '../../redux/actions/notistack';
import { MAX_USERS_PER_GROUP, MAX_CUSTOM_ATTRIBUTES } from '../../configs';


const CreateGroup = () => {
	const dispatch = useDispatch();
	const { name, members } = useSelector(state => state.groups.group_form);
	const { create_group_pending, create_group_success, form_errors } = useSelector(state => state.groups);
	const [formValid, setFormValid] = useState(false);
	const [csvUploadPending, setCsvUploadPending] = useState(false);
	// Does not seem like there is an easy way to reset file input field, use this to rerender input field
	const [inputFieldKey, setInputFieldKey] = useState('');

	useEffect(() => {
		const valid = (form_errors['name'] === false) && (form_errors['members'] === false);
		setFormValid(valid);
	}, [form_errors]);

	useEffect(() => {
		if (create_group_success) {
			dispatch(clearGroupForm());
		}
	}, [create_group_success]);

	useEffect(() => {
		// This ugly hack is needed because CSVReader does not have any callback for when file is uploaded
		const csvInput = window.document.getElementById('react-csv-reader-input')
		const eventListener = csvInput.addEventListener('change', (event) => {
			if (event.target.files.length > 0) {
				setCsvUploadPending(true);
			}
		})
		return () => {
			csvInput.removeEventListener('change', eventListener);
		}
	}, [dispatch]);

	const handleNameChange = (event) => {
		const value = event.target.value;
		dispatch(setGroupFormField('name', value));
	}

	const handleCsvFileLoaded = (data, fileInfo) => {
		const users = data.map(details => {
			const first_name = details.name;
			delete details.name;
			const last_name = details.surname;
			delete details.surname
			const user_id = normalizeTelephoneNumber(details.contact_number);
			delete details.contact_number
			const custom_attributes = details

			return { first_name, last_name, user_id, custom_attributes };
		});
		
		if (areUsersValid(users)) {
			dispatch(setGroupFormField('members', users));
		}
		setCsvUploadPending(false);
	}

	const handleSubmit = () => {
		dispatch(createGroup({ group_name: name, members }));
	}

	const areUsersValid = (users) => {
		const resetFileInput = () => {
			setInputFieldKey(new Date());
			dispatch(setGroupFormField('members', []));
		}

		if (users.length === 0) {
			resetFileInput();
			dispatch(notistackError("CSV file has no contacts. Please upload a file with contacts."));
			return false;
		}


		if (users.length > MAX_USERS_PER_GROUP) {
			resetFileInput();
			dispatch(notistackError("CSV file has too many contacts. Consider splitting the file into smaller files."));
			return false;
		}

		const invalid = users.some((user, index) => {
			
			const num_custom_attrs = Object.keys(user.custom_attributes).length
			if (num_custom_attrs > MAX_CUSTOM_ATTRIBUTES) {
				dispatch(notistackError(`Too many custom attributes: ${num_custom_attrs}`));
				return true;
			}

			if (user.first_name && user.last_name && user.user_id) {
				return false;
			}
			else {
				dispatch(notistackError(`CSV file error at line ${index + 2}`));
				return true;
			}
		});

		if (invalid) resetFileInput();

		return !invalid;
	}

	const renderIcon = () => {
		if (csvUploadPending) {
			return <CircularProgress disableShrink size={20} color="primary"/>;
		}
		if (members.length > 0) {
			return <CloudDoneOutlinedIcon size={21} color="primary"/>;
		}
		return <CloudUploadOutlinedIcon size={21} color="primary"/>;
	}

	return (
		<Container>
			<VerticalCentered><InfoIcon /><Box padding={1}> If you would like to create a Simple Group ensure the CSV file contains only the following 3 columns: <Box display="inline" fontWeight="bold">Name, Surname, Contact number</Box>, including a header row.</Box></VerticalCentered>
			<VerticalCentered><InfoIcon /><Box padding={1}> If you would like to create a <ContactsIcon style={{ fontSize: 12 }} /> Contact Group ensure the CSV file contains the following columns: <Box display="inline" fontWeight="bold">Name, Surname, Contact number</Box> (including a header row), and at least one custom field (<Box display="inline" fontWeight="bold">There is a max of 30 custom fields</Box>). Futher fields can be added with any names e.g. <Box display="inline" fontWeight="bold">Division, Facility, etc..</Box> (this name must also be included in the header row)</Box></VerticalCentered>
			<VerticalCentered><WarningIcon /><Box padding={1}>A maximum of <Box display="inline" fontWeight="bold">10,000</Box> contacts are allowed per group</Box></VerticalCentered>
			<TextField value={name} onChange={handleNameChange} label="Group Name" variant="outlined" />

			<CSVReaderContainer>
				{renderIcon()}
				<CSVReader
					label="Upload CSV file: "
					parserOptions={{
						header: true,
						transformHeader: (header) => { return header.trim().replace(/\s/g, "_").toLowerCase() },
						transform: (value) => { return value.trim() },
						skipEmptyLines: true,
					}}
					inputId="react-csv-reader-input"
					disabled={csvUploadPending}
					onFileLoaded={handleCsvFileLoaded}
					key={inputFieldKey}
				/>
			</CSVReaderContainer>

			<ButtonContainer>
				<Button variant="contained" color="primary" onClick={handleSubmit} disabled={!formValid || csvUploadPending || create_group_pending}>
					Submit
				</Button>
			</ButtonContainer>

		</Container>
	)
}

const CSVReaderContainer = styled.div`
	display: grid;
	grid-template-columns: 21px 1fr;
	grid-template-rows: 21px;
	align-items: center;
	gap: 10px;
`;

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 16px;
	padding: 48px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
`;

const VerticalCentered = styled.div`
	display: flex;
	align-items: center;
`;

export default CreateGroup;
