import axios from 'axios';
import pako from "pako";
import {
	apiRequest,
	API_POST,
	API_GET,
	API_PATCH,
	API_DELETE
} from '../../actions/api';

import {
	CREATE_GROUP,
	GET_GROUPS,
	GET_GROUP,
	GET_GROUP_MEMBERS,
	UPDATE_GROUP,
	SET_GROUP_FORM_FIELD,
	DELETE_GROUP,
	ARCHIVE_GROUP,
	UNARCHIVE_GROUP,
	GET_FILTER_MEMBERS,
	setCreateGroupPending,
	setCreateGroupSuccess,
	setCreateGroupFailed,
	setGroups,
	setCurrentGroup,
	setCurrentGroupMembers,
	setUpdateGroupPending,
	setUpdateGroupSuccess,
	clearGroups,
	clearCurrentGroup,
	clearCurrentGroupMembers,
	setFormFieldError,
	setGetGroupMembersPending,
	setGetGroupMembersSuccess,
	setGetGroupMembersFailed,
	getGroups,
	setGetFilterMembersPending,
	setGetFilterMembersSuccess,
	setGetFilterMembersFailed,
	setFilterMembers,
	clearFilterMembers
} from '../../actions/groups';

import { MAX_SIZE_PAYLOAD, MIN_COMPRESSION_SIZE} from '../../../configs';
import { requiredCheck, minArrayLengthCheck, validateField } from '../../../utils/validators';
import { lessThenMaxSizeEncoded } from "../../../utils";
import { notistackError, notistackSuccess } from "../../actions/notistack";

const validationSettings = {
	name: [requiredCheck],
	members: [(value) => minArrayLengthCheck(1, value)]
};

const createGroupTransforms = [
	...axios.defaults.transformRequest,
	(data) => {
		if (lessThenMaxSizeEncoded(data, MAX_SIZE_PAYLOAD)) {
			return data;
		}
		throw new Error(
			"CSV file is too large. Consider splitting the file into smaller files.", 
			{ cause: "MAX_SIZE_PAYLOAD"}
		);
	},
	(data, headers) => {
		// compress data if over 16kb
		if (lessThenMaxSizeEncoded(data, MIN_COMPRESSION_SIZE)) {
			return data;
		} else {
			headers['Content-Encoding'] = 'gzip';
			return pako.gzip(data);
		}
	}
]

const groups = ({ dispatch, getState }) => next => action => {

	next(action);
	
	switch (action.type) {
		case CREATE_GROUP:
			next(setCreateGroupPending());
			next(
				apiRequest({
					path: 'group',
					method: API_POST,
					data: action.payload,
					extraOptions: {
						transformRequest: createGroupTransforms
					},
					success: () => {
						next(notistackSuccess("Create group requested"));
						dispatch(setCreateGroupSuccess());
					},
					failure: (error) => {
						if (error.code === "ECONNABORTED") {
							next(notistackError("Unable to create group, request timeout"));
						} else if (error.cause === "MAX_SIZE_PAYLOAD") {
							next(notistackError("Unable to create group, file too large"));
						} else {
							next(notistackError("Unable to create group"));
						}
						dispatch(setCreateGroupFailed())
					}
				})
			);
			break;
		case GET_GROUPS:
			const { archive_visibility } = action.payload;

			next(clearGroups());
			next(
				apiRequest({
					path: `group?filter=${archive_visibility}`,
					method: API_GET,
					success: setGroups
				})
			);
			break;
		case GET_GROUP: {
			const { group_id } = action.payload;

			next(clearCurrentGroup());
			next(
				apiRequest({
					path: `groups/${group_id}`,
					method: API_GET,
					success: setCurrentGroup
				})
			);
			break;
		}
		case GET_GROUP_MEMBERS: {
			const { group_id } = action.payload;

			next(clearCurrentGroupMembers());
			next(setGetGroupMembersPending());
			next(
				apiRequest({
					path: `group/${group_id}/members`,
					method: API_GET,
					success: (data) => {
						next(setCurrentGroupMembers(data));
						return setGetGroupMembersSuccess();
					},
					failure: setGetGroupMembersFailed
				})
			);
			break;
		}
		case UPDATE_GROUP: {
			const { group_id, name, members } = action.payload;

			next(setUpdateGroupPending());
			next(
				apiRequest({
					path: `groups/${group_id}`,
					method: API_PATCH,
					data: {
						name,
						members
					},
					success: setUpdateGroupSuccess
				})
			);
			break;
		}
		case SET_GROUP_FORM_FIELD: {
			const field = action.payload.field;
			const value = action.payload.value;

			const error = validateField(field, value, validationSettings);
			next(setFormFieldError(error));
			break;
		}
		case DELETE_GROUP: {
			const { group_id } = action.payload;

			next(
				apiRequest({
					path: `groups/${group_id}`,
					method: API_DELETE,
					success: getGroups
				})
			);
			break;
		}
		case ARCHIVE_GROUP: {
			const { group_id, view_archived } = action.payload;

			next(
				apiRequest({
					path: `group/${group_id}`,
					method: API_DELETE,
					success: getGroups.bind(this, view_archived)
				})
			);
			break;
		}
		case UNARCHIVE_GROUP: {
			const { group_id, view_archived } = action.payload;

			next(
				apiRequest({
					path: `group/${group_id}?unarchive`,
					method: API_DELETE,
					success: getGroups.bind(this, view_archived)
				})
			);
			break;
		}
		case GET_FILTER_MEMBERS: {
			const { group_id } = action.payload.group_id;
			
			next(clearFilterMembers());
			next(setGetFilterMembersPending());
			next(
				apiRequest({
					path: `group/${group_id}/members`,
					method: API_GET,
					success: (data) => {
						next(setFilterMembers(data));
						return setGetFilterMembersSuccess();
					},
					failure: setGetFilterMembersFailed
				})
			);
			break;
		}
		default:
			break;
	}
};

export default groups;