export const SET_GROUP_FORM_FIELD = 'SET_GROUP_FORM_FIELD';
export const SET_GROUP_FORM_FIELD_ERROR = 'SET_GROUP_FORM_FIELD_ERROR';
export const CLEAR_GROUP_FORM = 'CLEAR_GROUP_FORM';

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_PENDING = 'CREATE_GROUP_PENDING';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

export const GET_GROUPS = 'GET_GROUPS';
export const SET_GROUPS = 'SET_GROUPS';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';

export const GET_GROUP = 'GET_GROUP';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const CLEAR_CURRENT_GROUP = 'CLEAR_CURRENT_GROUP';

export const GET_GROUP_MEMBERS = 'GET_GROUP_MEMBERS';
export const GET_GROUP_MEMBERS_PENDING = 'GET_GROUP_MEMBERS_PENDING';
export const GET_GROUP_MEMBERS_SUCCESS = 'GET_GROUP_MEMBERS_SUCCESS';
export const GET_GROUP_MEMBERS_FAILED = 'GET_GROUP_MEMBERS_FAILED';
export const SET_CURRENT_GROUP_MEMBERS = 'SET_CURRENT_GROUP_MEMBERS';
export const CLEAR_CURRENT_GROUP_MEMBERS = 'CLEAR_CURRENT_GROUP_MEMBERS';

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_PENDING = 'UPDATE_GROUP_PENDING';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

export const DELETE_GROUP = 'DELETE_GROUP';
export const ARCHIVE_GROUP = 'ARCHIVE_GROUP';
export const UNARCHIVE_GROUP = 'UNARCHIVE_GROUP';

export const SET_FILTER_GROUPS = 'SET_FILTER_GROUPS';
export const CLEAR_FILTER_GROUPS = 'CLEAR_FILTER_GROUPS';

export const GET_FILTER_MEMBERS = 'GET_FILTER_MEMBERS';
export const GET_FILTER_MEMBERS_PENDING = 'GET_FILTER_MEMBERS_PENDING';
export const GET_FILTER_MEMBERS_SUCCESS = 'GET_FILTER_MEMBERS_SUCCESS';
export const GET_FILTER_MEMBERS_FAILED = 'GET_FILTER_MEMBERS_FAILED';

export const SET_FILTER_MEMBERS = 'SET_FILTER_MEMBERS';
export const CLEAR_FILTER_MEMBERS = 'CLEAR_FILTER_MEMBERS';

export const setGroupFormField = (field, value) => ({ type: SET_GROUP_FORM_FIELD, payload: { field, value } });
export const setFormFieldError = (payload) => ({ type: SET_GROUP_FORM_FIELD_ERROR, payload });
export const clearGroupForm = () => ({ type: CLEAR_GROUP_FORM });

export const createGroup = (payload) => ({ type: CREATE_GROUP, payload });
export const setCreateGroupPending = () => ({ type: CREATE_GROUP_PENDING });
export const setCreateGroupSuccess = () => ({ type: CREATE_GROUP_SUCCESS });
export const setCreateGroupFailed = () => ({ type: CREATE_GROUP_FAILED });

export const getGroups = (archive_visibility) => ({ type: GET_GROUPS, payload: { archive_visibility } });
export const setGroups = (payload) => ({ type: SET_GROUPS, payload });
export const clearGroups = () => ({ type: CLEAR_GROUPS });

export const getGroup = (group_id) => ({ type: GET_GROUP, payload: { group_id } });
export const setCurrentGroup = (payload) => ({ type: SET_CURRENT_GROUP, payload });
export const clearCurrentGroup = () => ({ type: CLEAR_CURRENT_GROUP });

export const getGroupMembers = (group_id) => ({ type: GET_GROUP_MEMBERS, payload: { group_id } });
export const setGetGroupMembersPending = () => ({ type: GET_GROUP_MEMBERS_PENDING });
export const setGetGroupMembersSuccess = () => ({ type: GET_GROUP_MEMBERS_SUCCESS });
export const setGetGroupMembersFailed = () => ({ type: GET_GROUP_MEMBERS_FAILED });
export const setCurrentGroupMembers = (payload) => ({ type: SET_CURRENT_GROUP_MEMBERS, payload });
export const clearCurrentGroupMembers = () => ({ type: CLEAR_CURRENT_GROUP_MEMBERS });

export const updateGroup = (payload) => ({ type: UPDATE_GROUP, payload });
export const setUpdateGroupPending = () => ({ type: UPDATE_GROUP_PENDING });
export const setUpdateGroupSuccess = () => ({ type: UPDATE_GROUP_SUCCESS });

export const deleteGroup = (group_id) => ({ type: DELETE_GROUP, payload: { group_id } });
export const archiveGroup = (group_id, view_archived) => ({ type: ARCHIVE_GROUP, payload: { group_id, view_archived } });
export const unarchiveGroup = (group_id, view_archived) => ({ type: UNARCHIVE_GROUP, payload: { group_id, view_archived } });

export const setFilterGroups = (groups_ids) => ({ type: SET_FILTER_GROUPS, payload: { groups_ids } });
export const clearFilterGroups = () => ({ type: CLEAR_FILTER_GROUPS });

export const getFilterMembers = (group_id) => ({ type: GET_FILTER_MEMBERS, payload: { group_id } });
export const setGetFilterMembersPending = () => ({ type: GET_FILTER_MEMBERS_PENDING });
export const setGetFilterMembersSuccess = () => ({ type: GET_FILTER_MEMBERS_SUCCESS });
export const setGetFilterMembersFailed = () => ({ type: GET_FILTER_MEMBERS_FAILED });

export const setFilterMembers = (members) => ({ type: SET_FILTER_MEMBERS, payload: { members } });
export const clearFilterMembers = () => ({ type: CLEAR_FILTER_MEMBERS });
