import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import styled from 'styled-components';
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Card from '@material-ui/core/Card';
import {makeStyles} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

import ShakeAnimation from '../components/ShakeAnimation';

import {login} from "../../redux/actions/auth";

const validateForm = (_username, _password) => {
    const username = validateEmail(_username);
    const password = validatePassword(_password);

    const allFieldsTouched = username.touched && password.touched;
    const allFieldsValid = username.isValid && password.isValid;

    return {
        username,
        password,
        form: {
            touched: allFieldsTouched,
            isValid: allFieldsValid,
            showError: allFieldsTouched && !allFieldsValid
        }
    }
};

const validateEmail = (email) => {
    const touched = email !== null;
    const isValid = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));

    return {
        touched,
        isValid,
        showError: touched && !isValid
    }
};

const validatePassword = (password) => {
    const touched = password !== null;
    const isValid = password && password.length >= 6;

    return {
        touched,
        isValid,
        showError: touched && !isValid
    }
};


const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const [password, setPassword] = useState(null);
    const [username, setUsername] = useState(null);

    const {loginPending, loginSuccess, loginFailed} = useSelector(state => state.auth);

    const validation = validateForm(username, password);

    useEffect(() => {
        if (loginSuccess) {
            history.push('/main');
        }
    }, [loginSuccess]);
    

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                submitLogin();
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [validation]);

    const submitLogin = () => {
        if (validation.form.isValid) {
            dispatch(login({username, password}))
        }
    };

    return (
        <Container>

            <Splash>

                <Overlay>

                    <Card className={classes.card}>

                        <LogoContainer>
                            <img src="/res/images/logo.png" alt="logo" height={100}/>
                        </LogoContainer>

                        <Title>Login to your account</Title>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoFocus
                            autoComplete="email"
                            name="email_address"
                            label="Login with email"
                            type="email"
                            id="username"
                            error={validation.username.showError}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoFocus
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            id="password"
                            error={validation.password.showError}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />

                        <div className={classes.info}>Please contact <Link
                            href="mailto:support@a2d24.com" target="_blank" rel="noopener noreferrer"
                            variant="body2">
                            support@a2d24.com
                        </Link> for user management
                        </div>

                        <LoginButtonContainer>
                            <ShakeAnimation triggered={loginFailed}>
                                <Button color="primary" variant="contained" size="large" onClick={submitLogin}
                                        disabled={!validation.form.isValid || loginPending}>
                                    login
                                </Button>
                            </ShakeAnimation>
                        </LoginButtonContainer>
                    </Card>
                </Overlay>


            </Splash>

        </Container>
    )
};


const Container = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
`;

const BaseDiv = styled.div`
  margin: 0; padding: 0;
  width: 100%;
  height: 100%;
`;

const Splash = styled(BaseDiv)`
  background: #000f2e url("/res/images/splash.jpg") no-repeat;
  background-size: cover;
  background-position: center;
`;

const Overlay = styled(BaseDiv)`
  background-color: rgba(0,0,0,0.1);
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
`;

const Title = styled.p`
    font-weight: 300;
    font-size: 36px;
    margin: 0;
    padding-bottom: 24px;
`

const LogoContainer = styled.div`
    width: 100%;
    display: grid;
    justify-items: center;
    padding-bottom: 24px;
`;

const LoginButtonContainer = styled(BaseDiv)`
    width: 100%;
    display: grid;
    justify-items: center;
    justify-content: center;
`

const useStyles = makeStyles(theme => ({
    input: {
        color: "black"
    },
    card: {
        maxWidth: 516,
        padding: '52px',
        boxSizing: 'borderBox'
    },
    info: {
        color: theme.palette.grey[800],
        marginBottom: 16,
        marginTop: 16
    }

}));


export default Login