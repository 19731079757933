import {
    NOTISTACK_ERROR,
    NOTISTACK_INFO,
    NOTISTACK_SUCCESS,
    NOTISTACK_WARNING,
    RESET_NOTISTACK
} from "../actions/notistack";

const initState = {
    state: false,
    message: false
};

const notistackReducer = (state = initState, action) => {
    switch (action.type) {
        case NOTISTACK_SUCCESS:
            return { ...state, state: "success", message: action.payload};

        case NOTISTACK_ERROR:
            return { ...state, state: "error", message: action.payload};

        case NOTISTACK_INFO:
            return { ...state, state: "info", message: action.payload};

        case NOTISTACK_WARNING:
            return { ...state, state: "warning", message: action.payload};

        case RESET_NOTISTACK:
            return { ...state, state: false, message: false};

        default:
            return state;
    }
};

export default notistackReducer;