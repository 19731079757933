import axios from "axios";

import {
    API_REQUEST,
    API_GET,
    API_PUT,
    API_POST,
    API_PATCH,
    API_SET_HEADER,
    API_CLEAR_HEADER,
    API_DELETE,
    apiAuthError,
} from '../../actions/api';

import { API_ROOT_URL, API_GATEWAY_REQUEST_TIMEOUT } from '../../../configs';
import { notistackError } from "../../actions/notistack";

let headers = {};

const getOptions = (extraOptions) => {
    return {
        timeout: API_GATEWAY_REQUEST_TIMEOUT,
        headers: headers,
        ...extraOptions
    };
};

const axiosInstance = axios.create({
    baseURL: API_ROOT_URL,
    timeout: API_GATEWAY_REQUEST_TIMEOUT,
});


const api = ({dispatch, getState}) => next => action => {

    next(action);

    if (!([API_REQUEST, API_SET_HEADER, API_CLEAR_HEADER].includes(action.type)))
        return;

    switch (action.type) {
        case API_SET_HEADER:
            headers[action.payload.key] = action.payload.value;
            return;
        case API_CLEAR_HEADER:
            delete headers[action.payload];
            return;
        default:
            break;
    }

    if (action.payload === undefined)
        return;

    const {path, method, data, success, failure, full_url, extraOptions = {}, rawResponse = false} = action.payload;

    const api_url = full_url || `${API_ROOT_URL}/${path}`;

    let requestPromise = null;

    switch (method) {
        case API_GET:
            requestPromise = axiosInstance.get(api_url, getOptions(extraOptions));
            break;
        case API_PUT:
            requestPromise = axiosInstance.put(api_url, data, getOptions(extraOptions));
            break;
        case API_POST:
            requestPromise = axiosInstance.post(api_url, data, getOptions(extraOptions));
            break;
        case API_PATCH:
            requestPromise = axiosInstance.patch(api_url, data, getOptions(extraOptions));
            break;
        case API_DELETE:
            requestPromise = axiosInstance.delete(api_url, getOptions(extraOptions));
            break;
        default:
            break;
    }

    if (requestPromise) {
        requestPromise
            .then(response => {
                if (success)
                    dispatch(success( rawResponse ? response : response.data));
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled');
                    return;
                }
                if (error.response && error.response.status !== undefined) {
                    if (error.response.status === 401) {
                        dispatch(apiAuthError());
                    }
                }

                console.error(error);

                if (failure) {
                    dispatch(failure(error));
                } else {
                    if (error.code === "ECONNABORTED") {
                        dispatch(notistackError("Request timeout"));
                    }
                }
            });
    }

};

export default api;
