import {apiRequest, API_POST, API_AUTH_ERROR, setHeader, clearHeader} from '../../actions/api'
import {
    AUTH_LOGIN,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT_USER,
    FETCH_AUTHENTICATED_USER_FROM_STORAGE,
    AUTHENTICATED_USER_FETCHED_FROM_STORAGE,
    userFetchedFromStorage,
    loginSuccess,
    loginFailure,
    setUser,
    logoutUser
} from "../../actions/auth";

import {saveToLocalStorage, fetchFromLocalStorage, removeFromLocalStorage} from "../../actions/storage"

import {DATA_TYPE_JSON} from "../core/storage";
import {push} from "../core/router";
import {notistackError} from "../../actions/notistack";

import {AUTH_URL} from '../../../configs';

const ACTION_TYPES_TO_PROCESS = [AUTH_LOGIN, AUTH_LOGIN_SUCCESS, API_AUTH_ERROR, AUTH_LOGOUT_USER,
    FETCH_AUTHENTICATED_USER_FROM_STORAGE, AUTHENTICATED_USER_FETCHED_FROM_STORAGE];

const auth = ({dispatch, getState}) => next => action => {
    if (!action)
        return;

    next(action);

    if (!(ACTION_TYPES_TO_PROCESS.includes(action.type)))
        return;

    switch (action.type) {
        case AUTH_LOGIN:
            next(
                apiRequest({
                    full_url: `${AUTH_URL}/login`,
                    method: API_POST,
                    data: {
                        'username': action.payload.username,
                        'password': action.payload.password
                    },
                    success: loginSuccess,
                    failure: loginFailure
                })
            );
            break;
        case AUTH_LOGIN_SUCCESS:
            console.log(action.payload)
            const user = {...action.payload, token: action.payload.access_token};
            next(saveToLocalStorage({key: 'user', type: DATA_TYPE_JSON, value: user}));
            next(setHeader({key: 'Authorization', value: user.token}));
            next(setUser(user));
            break;
        case AUTH_LOGOUT_USER:
            next(removeFromLocalStorage('user'));
            next(clearHeader('Authorization'));
            next(setUser({}));
            next(push('/login'));
            break;
        case API_AUTH_ERROR:
            next(logoutUser());
            next(notistackError("User not logged in"))
            break;
        case FETCH_AUTHENTICATED_USER_FROM_STORAGE:
            next(fetchFromLocalStorage({key: 'user', type: DATA_TYPE_JSON, onFetch: userFetchedFromStorage}));
            break;
        case AUTHENTICATED_USER_FETCHED_FROM_STORAGE:
            if (action.payload) {
                next(setUser(action.payload));
                next(setHeader({key: 'Authorization', value: action.payload.token}));
            } else {
                dispatch(logoutUser());
            }
            break;
        default:
            break;
    }

};

export default auth;
