import {isValidTelephoneNumber} from "./phone-number";

const TWILIO_MAX_WHATSAPP_MESSAGE_LENGTH = 1600;

const validateAllFields = (campaign_form, validationSettings, skip = []) => {
    const form_errors = {}
    Object.keys(validationSettings).forEach((key) => {
        if (skip.includes(key))
            return;
        let value = campaign_form?.[key];
        if (key === "template")
            value = campaign_form?.[key]?.template;
        if (key === "survey") 
            value = campaign_form?.[key]?.survey_items;
        form_errors[key] = validateField(key, value, validationSettings)[key]
    });
    return form_errors;
}

const validateField = (key, value, validationSettings) => {
    let invalid = false;
    if (validationSettings[key] !== undefined) {
        for (let i = 0; i < validationSettings[key].length; i++) { // interate on validators
            const validator = validationSettings[key][i];
            invalid = invalid || validator(value);
        }
    }
    let errorUpd = {};
    errorUpd[key] = invalid;
    return errorUpd;
};

// validation check functions
const requiredCheck = (value) => {
    if (!!value) {
        return false;
    } else {
        return 'This field is required';
    }
};

const minlengthCheck = (minlength, value) => {
    if (!value || value.length < minlength) {
        return 'It should be at least ' + minlength + ' characters long';
    } else {
        return false;
    }
};

const exactLengthCheck = (length, value) => {
    if (value.length === length)
        return false;

    return 'It should be ' + length + ' characters long';
};

const emailCheck = value => {
    if (/^\w+@\w+\.\w{2,3}$/.test(value)) {
        return false;
    } else {
        return 'This is not a valid email';
    }
};

const invalidTelephoneCheck = value => {
    if (isValidTelephoneNumber(value)) {
        return false;
    }

    return 'The supplied telephone number is invalid';
};

const luhnNumberCheck = value => {
    if (luhnCheck(value)) {
        return false;
    }

    return 'The provided input is not valid';
};

const sumDigits = number => {
    return (number + '')
        .split('')
        .map(x => parseInt(x))
        .reduce((acc, val) => acc + val);
};

const luhnCheck = num => {
    let arrayOfDigits = (num + '')
        .split('')
        .reverse()
        .map(x => parseInt(x));

    let luhnSum = sumDigits(arrayOfDigits.reduce((acc, x, i) => i % 2 !== 0 ? acc + sumDigits(x * 2) : acc + x, ''));

    return luhnSum % 10 === 0;
};

const minArrayLengthCheck = (minlength, value) => {
    if (value.length < minlength) {
        return 'More than ' + minlength + ' required';
    } else {
        return false;
    }
}

const messageMaxLengthCheck = (maxLength, value) => {
    if (value.length > maxLength) {
        return `Maximum length for a single message is ${maxLength} characters`;
    }
    return false;
}

const messagesCheck = messages => {
    const errors = messages.map(message => {
        const errorObject = {};
        errorObject['message_type'] = message.message_type ? false : 'Message type required';

        errorObject['language'] = Object.keys(message['language']).reduce((reduced, language) => {
            reduced[language] = message.language[language] ? false : 'This field is required';
            if (message.message_type === 'text') {
                reduced[language] = message.language[language].body ?
                    messageMaxLengthCheck(
                        TWILIO_MAX_WHATSAPP_MESSAGE_LENGTH,
                        message.language[language].body
                    )
                    : 'Message body required';
            } else if (message.message_type === 'media') {
                reduced[language] = message.language[language].url ? false : 'Message url required';
            }
            return reduced;
        }, {});

        return errorObject;
    });

    return errors;
}

const surveyCheck = surveyQuestions => {
    const errors = surveyQuestions.map(question => {
        const errorObject = {};
        errorObject['rating'] = `${question.rating}` ? false : 'Rating required'; //fine

        errorObject['text'] = {}
        question.text.forEach((text) => {
            errorObject['text'][text.language] = text.value ? false : 'This field is required';
        });

        if (!question.rating) {

            const option_reduced = question.options.map((val) => {
                let a = {}
                val.forEach((v) => {
                        a[v.language] = v.value
                    }
                )
                return a


            })
            errorObject['options'] = option_reduced.map(option => {
                const optionErrorObject = Object.keys(option).reduce((reduced, language) => {
                    reduced[language] = option[language] ? false : 'This field is required';
                    return reduced;
                }, {});

                return optionErrorObject;
            });
        }

        return errorObject;
    });

    return errors;
}

export {
    validateAllFields,
    validateField,
    requiredCheck,
    minlengthCheck,
    exactLengthCheck,
    emailCheck,
    luhnNumberCheck,
    invalidTelephoneCheck,
    minArrayLengthCheck,
    messagesCheck,
    surveyCheck
};