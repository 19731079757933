import React, {useState, useEffect} from 'react';
import {
    Switch,
    Route,
    Link,
    useLocation,
    Redirect,
    useHistory
} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {AccountCircle} from '@material-ui/icons';


import Campaigns from './Campaigns/Campaigns';
import Groups from './Groups/Groups';

import {fetchUserFromStorage, logoutUser} from '../redux/actions/auth';

import {KIBANA_ENDPOINT} from '../configs';
import {isEmpty} from "../utils/isEmpty";
import {withSnackbar} from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const drawerWidth = 240;
const hightlightColor = 'rgba(0, 48, 104, 0.3)';

const Main = () => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [initialized, setInitialized] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const loadSpinner = useSelector(state => state.auth.loadSpinner);

    useEffect(() => {
        if (!initialized) {
            dispatch(fetchUserFromStorage());
            setInitialized(true);
        }
    }, []);

    const goToKibana = () => {
        let kibana_window = window.open(KIBANA_ENDPOINT, '_blank');
        kibana_window.focus();
    }

    const handleRouteChange = (route) => {
        history.push(route);
    }

    const shouldHighlight = (route) => {
        if (location.pathname === route) {
            return {backgroundColor: hightlightColor};
        } else {
            return {};
        }
    }

    const handleLogout = () => {
        dispatch(logoutUser());
    }

    const render = () => {
        if (initialized) {
            return (
                <div className={classes.container}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={classes.toolBar}>
                            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
                                <AccountCircle fontSize="large" style={{color: "white"}}/>
                            </IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                                <MenuItem onClick={handleLogout}>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>

                    <Drawer
                        className={classes.drawer}
                        classes={{paper: classes.drawerPaper}}
                        variant="permanent"
                        anchor="left"
                    >

                        <div className={classes.logoContainer}>
                            <img src="/res/images/logo.png" alt="logo"/>
                        </div>
                        <Divider/>
                        <List>
                            <ListItem
                                button
                                onClick={() => {
                                    handleRouteChange('/main/campaigns/create')
                                }}
                                style={shouldHighlight('/main/campaigns/create')}
                            >
                                <ListItemText primary="Create Campaign"></ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => {
                                    handleRouteChange('/main/campaigns/table')
                                }}
                                style={shouldHighlight('/main/campaigns/table')}
                            >
                                <ListItemText primary="View Campaigns"></ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => {
                                    handleRouteChange('/main/groups/create')
                                }}
                                style={shouldHighlight('/main/groups/create')}
                            >
                                <ListItemText primary="Create Group"></ListItemText>
                            </ListItem>
                            <ListItem
                                button
                                onClick={() => {
                                    handleRouteChange('/main/groups/table')
                                }}
                                style={shouldHighlight('/main/groups/table')}
                            >
                                <ListItemText primary="Manage Groups"></ListItemText>
                            </ListItem>
                            <ListItem button onClick={goToKibana}>
                                <ListItemText primary="Dashboard"></ListItemText>
                            </ListItem>
                        </List>
                    </Drawer>

                    <Switch>
                        <React.Fragment>
                            <div className={classes.routeContainer}>
                                <ProtectedRoute path="/main/campaigns">
                                    <Campaigns/>
                                </ProtectedRoute>
                                <ProtectedRoute path="/main/groups">
                                    <Groups/>
                                </ProtectedRoute>
                            </div>
                        </React.Fragment>
                    </Switch>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            {render()}
            <Backdrop className={classes.backdrop} open={loadSpinner}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </React.Fragment>
    )
}

const ProtectedRoute = ({children, ...rest}) => {
    const {user} = useSelector(state => state.auth);

    const isAuthenticated = () => {
        if (!isEmpty(user) && !isTokenExpired()) {
            return true;
        } else {
            return false;
        }
    }

	const isTokenExpired = () => {
		const currentTimestamp = new Date().getTime();
		return currentTimestamp > (user.exp * 1000);
	}
	return (
		<Route {...rest} >
			{isAuthenticated() ? children : <Redirect to="/login" />}
		</Route>
	)
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        height: '100%',
        background: '#f2f3f3'
    },
    routeContainer: {
        width: '100%',
        padding: '24px',
        paddingTop: '88px'
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    toolBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '240px',
        minWidth: '240px',
        backgroundColor: theme.palette.background.paper
    },
    logoContainer: {
        width: '100%',
        display: 'grid',
        justifyItems: 'center',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#cbc5c5',
        backdropFilter: 'blur(5px)'

    },
}));

export default withSnackbar(Main);
