import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { IconButton, TextField, Button, DialogTitle, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import CampaignDetails from '../components/CampaignDetails';
import { createTestCampaign } from '../../../redux/actions/campaigns';
import { formatTemplateVariables } from '../../../utils/template';
import { GROUP_FILTER_TYPE } from '../../constants';

import { isValidTelephoneNumber, normalizeTelephoneNumber } from '../../../utils/phone-number';

const ReviewCampaign = () => {
	const dispatch = useDispatch();
	const [dialogOpen, setDialogOpen] = useState(false);
	const { campaign_form } = useSelector(state => state.campaigns);

	const campaign = {...campaign_form, template: formatTemplateVariables(campaign_form.template['template'], campaign_form.template['variables_value_mappings'])};

	const submitCreateTestCampaignRequest = (members) => {
		const templateText = campaign_form.template.variables ? formatTemplateVariables(campaign_form.template.template, campaign_form.template.variables_value_mappings) : campaign_form.template.template;
		const campain_members = members

		const create_campaign_payload = {
			...campaign_form,
			campaign_form: campaign_form,
			template_message: templateText,
			reprompt_message: campaign_form.template.reprompt_message,
			opt_in_keyword: campaign_form.template.opt_in_keyword,
			groups: campaign_form.groups.filter(group => group.type !== GROUP_FILTER_TYPE).map(group => group.group_id),
			campaign_members: campain_members,
			survey: { ...campaign_form.survey, languages: campaign_form.languages },
			template_variables: campaign_form.template?.variables_value_mappings || {},
			content_template_id: campaign_form.template?.content_template_id
		};
		dispatch(createTestCampaign(create_campaign_payload));
	}

	const openDialog = () => {
		setDialogOpen(true)
	}

	const handleCancel = () => {
		setDialogOpen(false)
	}

	const handleComplete = (members) => {
		submitCreateTestCampaignRequest(members)
		setDialogOpen(false)
	}

	return (
		<React.Fragment>
			<CampaignDetails campaign={campaign}/>

			<br />
			<Button onClick={openDialog} variant="contained" color="primary">
				Test Campaign
			</Button>

			<h4>If you wish to publish the campaign, please click confirm</h4>

			<TestCampaignDialog
				handleCancel={handleCancel}
				handleComplete={handleComplete}
				dialogOpen={dialogOpen}
			/>

		</React.Fragment>
	)
}

const MAX_TEST_NUMBERS = 6
const MIN_TEST_NUMBERS = 1

const TestCampaignDialog = ({ dialogOpen, handleCancel, handleComplete }) => {
	const [testMembers, setTestMembers] = useState([""])
	const [isValid, setIsValid] = useState(false)


	useEffect(() => {
		let valid = true
		if (testMembers.length === 0) {
			setIsValid(false)
			return
		}

		for (const testMember in testMembers) {
			const number = testMembers[testMember]

			if (!isValidTelephoneNumber(number)) {
				valid = false
				break;
			}

			if (normalizeTelephoneNumber(number).length !== 12) {
				valid = false
				break;
			}
		}
		setIsValid(valid)
	}, [testMembers])

	const handleChange = (e, idx) => {
		e.persist()
		if (e?.target) {
			setTestMembers(members => {
				const newMembers = [...members]
				newMembers[idx] = e.target.value
				return newMembers
			})
		}
	}

	const addMember = () => {
		setTestMembers(members => {
			const newMembers = [...members]
			newMembers.push("")
			return newMembers
		})
	}

	const removeMember = (idx) => {
		setTestMembers(members => {
			const newMembers = [...members]
			newMembers.splice(idx, 1)
			return newMembers
		})
	}

	return (
		<Dialog open={dialogOpen}>
			<DialogTitle>
				Test Campaign
			</DialogTitle>
			<DialogContent>
				<DialogText>
					Please add up to 6 valid South African numbers that you would like to send a test campaign to:
				</DialogText>
				{testMembers.map((member, idx) => {
					return (
						<TextContainer key={idx}>
							<TextField value={member} onChange={(e) => handleChange(e, idx)} />
							<IconButton
								style={{ "marginLeft": "0.5rem" }}
								color="primary"
								disabled={testMembers.length === MIN_TEST_NUMBERS}
								onClick={() => removeMember(idx)} >
								<RemoveCircleIcon />

							</IconButton>
						</TextContainer>
					)
				})}
				<TextContainer>
					<TextField disabled />
					<IconButton
						style={{ "marginLeft": "0.5rem" }}
						color="primary"
						disabled={testMembers.length >= MAX_TEST_NUMBERS}
						onClick={addMember} >
						<AddCircleIcon />
					</IconButton>
				</TextContainer>
			</DialogContent>


			<DialogActions>
				<Button onClick={handleCancel} color="secondary">
					Cancel
				</Button>
				<Button onClick={() => handleComplete(testMembers)} disabled={!isValid} color="primary">
					Complete
				</Button>
			</DialogActions>
		</Dialog>
	)

}

const DialogText = styled.p`
	margin-bottom: 0.5rem;
`


const TextContainer = styled.div`
	margin-left: 1rem;
	margin-bottom: 0.4rem;
	margin-top: 0.4rem;
	display: flex;
	align-items: center;
`;

export default ReviewCampaign;